define("discourse/plugins/discourse-multilingual/discourse/components/legacy-table-header-toggle", ["exports", "@ember/component", "discourse-common/lib/icon-library", "@ember/template", "@ember/runloop", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _iconLibrary, _template, _runloop, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("order", "field", "asc"), (_obj = {
    tagName: "th",
    classNames: ["sortable"],
    attributeBindings: ["title", "colspan", "ariaSort:aria-sort", "role"],
    role: "columnheader",
    labelKey: null,
    chevronIcon: null,
    columnIcon: null,
    translated: false,
    automatic: false,
    onActiveRender: null,
    pressedState: null,
    ariaLabel: null,
    ariaSort() {
      if (this.order === this.field) {
        return this.asc ? "ascending" : "descending";
      } else {
        return "none";
      }
    },
    toggleProperties() {
      if (this.order === this.field) {
        this.set("asc", this.asc ? null : true);
      } else {
        this.setProperties({
          order: this.field,
          asc: null
        });
      }
    },
    toggleChevron() {
      if (this.order === this.field) {
        let chevron = (0, _iconLibrary.iconHTML)(this.asc ? "chevron-up" : "chevron-down");
        this.set("chevronIcon", (0, _template.htmlSafe)(`${chevron}`));
      } else {
        this.set("chevronIcon", null);
      }
    },
    click() {
      this.toggleProperties();
    },
    keyPress(e) {
      if (e.which === 13) {
        this.toggleProperties();
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.automatic && !this.translated) {
        this.set("labelKey", this.field);
      }
      this.set("id", `table-header-toggle-${this.field.replace(/\s/g, "")}`);
      this.toggleChevron();
      this._updateA11yAttributes();
    },
    didRender() {
      if (this.onActiveRender && this.chevronIcon) {
        this.onActiveRender(this.element);
      }
    },
    _updateA11yAttributes() {
      let criteria = "";
      const pressed = this.order === this.field;
      if (this.icon === "heart") {
        criteria += `${_I18n.default.t("likes_lowercase", {
          count: 2
        })} `;
      }
      if (this.translated) {
        criteria += this.field;
      } else {
        const labelKey = this.labelKey || `directory.${this.field}`;
        criteria += _I18n.default.t(labelKey + "_long", {
          defaultValue: _I18n.default.t(labelKey)
        });
      }
      this.set("ariaLabel", _I18n.default.t("directory.sort.label", {
        criteria
      }));
      if (pressed) {
        if (this.asc) {
          this.set("pressedState", "mixed");
        } else {
          this.set("pressedState", "true");
        }
        this._focusHeader();
      } else {
        this.set("pressedState", "false");
      }
    },
    _focusHeader() {
      (0, _runloop.schedule)("afterRender", () => {
        document.getElementById(this.id)?.focus();
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "ariaSort", [_dec], Object.getOwnPropertyDescriptor(_obj, "ariaSort"), _obj)), _obj)));
});