define("discourse/plugins/discourse-multilingual/discourse/components/content-language-discovery", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "I18n"], function (_exports, _component, _object, _service, _multilingual, _multilingualRoute, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContentLanguageDiscovery = _exports.default = (_class = class ContentLanguageDiscovery extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
    }
    get shouldRender() {
      return this.siteSettings.multilingual_enabled && this.siteSettings.multilingual_content_languages_enabled && this.siteSettings.multilingual_content_languages_topic_filtering_enabled && (this.currentUser || this.router.currentRouteName.indexOf("categories") === -1);
    }
    get contentLanguages() {
      let contentLangs = this.currentUser ? this.currentUser.content_languages : this.site.content_languages;
      if (contentLangs) {
        if (this.currentUser) {
          if (!contentLangs.some(l => l.locale === "set_content_language")) {
            contentLangs.push({
              icon: "plus",
              locale: "set_content_language",
              name: _I18n.default.t("user.content_languages.set")
            });
          }
        } else {
          contentLangs.forEach(l => {
            (0, _object.set)(l, "classNames", "guest-content-language");
          });
        }
      }
      return contentLangs;
    }
    get hasLanguages() {
      let hasLangs;
      if (this.currentUser && this.contentLanguages) {
        hasLangs = this.contentLanguages.filter(l => (0, _multilingual.isContentLanguage)(l.locale, this.siteSettings)).length > 0;
      } else {
        hasLangs = (0, _multilingualRoute.getDiscoveryParam)(this, _multilingualRoute.contentLanguageParam);
      }
      return hasLangs;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});