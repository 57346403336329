define("discourse/plugins/discourse-multilingual/discourse/components/admin-language-list", ["exports", "@ember/object", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _object, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("allContentEnabled"), _dec2 = (0, _decorators.observes)("allInterfaceEnabled"), (_obj = {
    classNames: "admin-language-list",
    controlColumnClassNames: ["language-control"],
    allContentEnabled: false,
    allInterfaceEnabled: false,
    updateAllContent() {
      this.languages.forEach(l => {
        (0, _object.set)(l, "content_enabled", this.allContentEnabled);
      });
    },
    updateAllInterface() {
      this.languages.forEach(l => {
        if (l.locale !== "en") {
          (0, _object.set)(l, "interface_enabled", this.allInterfaceEnabled);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "updateAllContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateAllContent"), _obj), _applyDecoratedDescriptor(_obj, "updateAllInterface", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateAllInterface"), _obj)), _obj)));
});