define("discourse/plugins/discourse-multilingual/discourse/components/multilingual-uploader", ["exports", "@ember/object/computed", "@ember/component", "discourse/mixins/uppy-upload", "@ember/object/evented", "discourse-common/utils/decorators", "I18n"], function (_exports, _computed, _component, _uppyUpload, _evented, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend(_uppyUpload.default, (_dec = (0, _decorators.default)("uploadType"), (_obj = {
    type: "yml",
    addDisabled: (0, _computed.alias)("uploading"),
    elementId: "multilingual-uploader",
    preventDirectS3Uploads: true,
    classNameBindings: [":multilingual-uploader", "uploadType"],
    locale: null,
    message: null,
    _init: (0, _evented.on)("didInsertElement", function () {
      this.messageBus.subscribe("/uploads/" + this.type, msg => {
        if (msg.uploaded) {
          this.setProperties({
            uploading: false,
            message: _I18n.default.t("uploaded")
          });
          this.done();
        } else if (msg.errors) {
          this.set("message", msg.errors[0]);
        }
        setTimeout(() => {
          this.set("message", null);
        }, 10000);
      });
    }),
    uploadUrl(uploadType) {
      return `/admin/multilingual/${uploadType}s`;
    },
    uploadDone() {
      // wait for message that uploaded file is processed.
    },
    validateUploadedFilesOptions() {
      return {
        skipValidation: true
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "uploadUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "uploadUrl"), _obj)), _obj)));
});