define("discourse/plugins/discourse-multilingual/discourse/components/admin-language", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-language", "@ember/component", "discourse-common/lib/object"], function (_exports, _decorators, _multilingualLanguage, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("language.content_enabled", "language.interface_enabled"), _dec2 = (0, _decorators.default)("language.custom"), _dec3 = (0, _decorators.default)("language.locale"), _dec4 = (0, _decorators.default)("language.content_tag_conflict"), _dec5 = (0, _decorators.default)("language.custom"), (_obj = {
    tagName: "tr",
    classNames: "language",
    didInsertElement() {
      this.currentLanguage = JSON.parse(JSON.stringify(this.language));
    },
    trackUpdates() {
      if ((0, _object.deepEqual)(this.currentLanguage, this.language)) {
        this.updatedLanguages.removeObject(this.language);
      } else {
        this.updatedLanguages.addObject(this.language);
      }
    },
    typeKey(custom) {
      return `multilingual.languages.${custom ? "custom" : "base"}`;
    },
    interfaceToggleDisabled(locale) {
      return locale === "en";
    },
    contentDisabled(tagConflict) {
      return !this.siteSettings.multilingual_content_languages_enabled || tagConflict;
    },
    interfaceDisabled() {
      return !this.siteSettings.allow_user_locale;
    },
    actionsDisabled(custom) {
      return !custom;
    },
    contentClass() {
      return this.generateControlColumnClass("content");
    },
    interfaceClass() {
      return this.generateControlColumnClass("interface");
    },
    actionsClass() {
      return this.generateControlColumnClass("actions");
    },
    generateControlColumnClass(type) {
      let columnClass = `language-control ${type}`;
      if (this.get(`${type}Disabled`)) {
        columnClass += " disabled";
      }
      return columnClass;
    },
    actions: {
      remove() {
        this.set("removing", true);
        let locales = [this.get("language.locale")];
        _multilingualLanguage.default.remove(locales).then(result => {
          this.set("removing", false);
          this.removed(result);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "trackUpdates", [_dec], Object.getOwnPropertyDescriptor(_obj, "trackUpdates"), _obj), _applyDecoratedDescriptor(_obj, "typeKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "typeKey"), _obj), _applyDecoratedDescriptor(_obj, "interfaceToggleDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "interfaceToggleDisabled"), _obj), _applyDecoratedDescriptor(_obj, "contentDisabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "contentDisabled"), _obj), _applyDecoratedDescriptor(_obj, "interfaceDisabled", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "interfaceDisabled"), _obj), _applyDecoratedDescriptor(_obj, "actionsDisabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "actionsDisabled"), _obj), _applyDecoratedDescriptor(_obj, "contentClass", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "contentClass"), _obj), _applyDecoratedDescriptor(_obj, "interfaceClass", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "interfaceClass"), _obj), _applyDecoratedDescriptor(_obj, "actionsClass", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "actionsClass"), _obj)), _obj)));
});