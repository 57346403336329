define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/helpers/concat-class", "discourse-i18n", "truth-helpers/helpers/eq", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _service, _concatClass, _discourseI18n, _eq, _multilingualRoute, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _LanguageSwitcherMenu;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LanguageSwitcherMenu = _exports.default = (_class = (_LanguageSwitcherMenu = class LanguageSwitcherMenu extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get currentLocale() {
      return _discourseI18n.default.currentLocale();
    }
    change(locale1) {
      (0, _multilingualRoute.addParam)(_multilingualRoute.localeParam, locale1, {
        add_cookie: true,
        ctx: this
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="language-switcher-menu">
        <ul>
          {{#each this.site.interface_languages as |l|}}
            <li>
              {{! template-lint-disable no-invalid-interactive }}
              <a
                class={{concatClass
                  "ls-language"
                  (if (eq l.locale this.currentLocale) "current")
                }}
                {{on "click" (fn this.change l.locale)}}
                label={{l.name}}
              >
                {{l.name}}
              </a>
            </li>
          {{/each}}
        </ul>
      </div>
    
  */
  {
    "id": "AuhWQlFl",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"language-switcher-menu\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"site\",\"interface_languages\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n\"],[1,\"            \"],[11,3],[16,0,[28,[32,0],[\"ls-language\",[52,[28,[32,1],[[30,1,[\"locale\"]],[30,0,[\"currentLocale\"]]],null],\"current\"]],null]],[16,\"label\",[30,1,[\"name\"]]],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"change\"]],[30,1,[\"locale\"]]],null]],null],[12],[1,\"\\n              \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"l\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu.js",
    "scope": () => [_concatClass.default, _eq.default, _modifier.on, _helper.fn],
    "isStrictMode": true
  }), _LanguageSwitcherMenu), _LanguageSwitcherMenu), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
});