define("discourse/plugins/discourse-multilingual/discourse/initializers/multilingual-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-tag", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/models/composer", "discourse-common/lib/icon-library", "discourse/lib/render-tag", "@ember/object", "@ember/runloop", "discourse/plugins/discourse-multilingual/discourse/components/language-switcher"], function (_exports, _pluginApi, _decorators, _multilingualTag, _multilingualRoute, _multilingual, _composer, _iconLibrary, _renderTag, _object, _runloop, _languageSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: "multilingual",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      if (!siteSettings.multilingual_enabled) {
        return;
      }
      if (siteSettings.multilingual_content_languages_enabled) {
        _composer.default.serializeOnCreate("content_language_tags", "content_language_tags");
        _composer.default.serializeToTopic("content_language_tags", "topic.content_language_tags");
      }
      (0, _pluginApi.withPluginApi)("1.28.0", api => {
        var _dec, _dec2, _obj;
        api.replaceTagRenderer(_multilingualTag.multilingualTagRenderer);
        _multilingualRoute.discoveryParams.forEach(param => {
          api.addDiscoveryQueryParam(param, {
            replace: true,
            refreshModel: true
          });
        });
        api.onPageChange(() => (0, _multilingualRoute.removeParam)(_multilingualRoute.localeParam, {
          ctx: this
        }));
        api.modifyClass("controller:preferences/interface", (_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("makeThemeDefault"), (_obj = {
          pluginId: "discourse-multilingual",
          availableLocales() {
            return this.site.interface_languages.map(l => {
              return {
                value: l.locale,
                name: l.name
              };
            });
          },
          saveAttrNames(makeDefault) {
            let attrs = this._super(makeDefault);
            attrs.push("custom_fields");
            return attrs;
          },
          actions: {
            save() {
              if (!siteSettings.multilingual_content_languages_enabled) {
                return this._super();
              }
              let cl = this.model.custom_fields.content_languages;
              if (!cl || !cl.length) {
                this.set("model.custom_fields.content_languages", [""]);
              }
              return this._super().then(() => {
                const contentLanguages = this.site.content_languages;
                let rawUserLanguages = this.model.custom_fields.content_languages;
                let userLanguages = [];
                if (typeof rawUserLanguages === "string") {
                  rawUserLanguages = [rawUserLanguages];
                }
                if (rawUserLanguages) {
                  userLanguages = rawUserLanguages.map(locale => {
                    return contentLanguages.find(l => l.locale === locale);
                  });
                }

                // See workaround above
                userLanguages = userLanguages.filter(l => l && (0, _multilingual.isContentLanguage)(l.locale, siteSettings));
                currentUser.set("content_languages", userLanguages);
              });
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "availableLocales", [_dec], Object.getOwnPropertyDescriptor(_obj, "availableLocales"), _obj), _applyDecoratedDescriptor(_obj, "saveAttrNames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveAttrNames"), _obj)), _obj)));
        api.modifyClass("component:tag-drop", {
          pluginId: "discourse-multilingual",
          _prepareSearch(query) {
            const data = {
              q: query,
              filterForInput: true,
              limit: this.get("siteSettings.max_tag_search_results")
            };
            this.searchTags("/tags/filter/search", data, this._transformJson);
          }
        });
        function tagDropCallback(item) {
          (0, _object.set)(item, "label", (0, _multilingualTag.multilingualTagTranslator)(item.name));
          return item;
        }
        function tagDropArrayCallback(content) {
          if (Array.isArray(content)) {
            return content.map(item => tagDropCallback(item));
          } else {
            return tagDropCallback(content);
          }
        }
        api.modifyClass("component:tag-drop", {
          pluginId: "discourse-multilingual",
          modifyContent(content) {
            return tagDropArrayCallback(content);
          }
        });
        api.modifyClass("component:selected-name", {
          pluginId: "discourse-multilingual",
          label: (0, _object.computed)("title", "name", function () {
            if (this.selectKit.options.headerComponent === "tag-drop/tag-drop-header") {
              let item = tagDropCallback(this.item);
              return item.label || this.title || this.name;
            } else {
              return this._super(...arguments);
            }
          })
        });
        api.addTagsHtmlCallback(function (topic) {
          const contentLanguageTags = topic.content_language_tags;
          if (!siteSettings.multilingual_content_languages_enabled || !contentLanguageTags || !contentLanguageTags[0]) {
            return;
          }
          let html = '<div class="content-language-tags">';
          html += (0, _iconLibrary.iconHTML)("translate");
          contentLanguageTags.forEach(t => {
            html += (0, _renderTag.default)(t, {
              contentLanguageTag: true,
              style: "content-language-tag"
            }) + " ";
          });
          html += "</div>";
          return html;
        }, {
          priority: 100
        });
        if (!currentUser && siteSettings.multilingual_guest_language_switcher === "header") {
          api.headerIcons.add("multilingual-language-switcher", _languageSwitcher.default);
        }
        api.modifyClass("route:tag-groups-edit", {
          pluginId: "discourse-multilingual",
          setupController(controller, model) {
            this._super(controller, model);
            if (model.content_language_group) {
              controller.setupContentTagControls();
            }
          },
          actions: {
            tagsChanged() {
              this.refresh();
            }
          }
        });
        api.modifyClass("controller:tag-groups-edit", {
          pluginId: "discourse-multilingual",
          setupContentTagControls() {
            (0, _runloop.scheduleOnce)("afterRender", () => {
              $(".tag-groups-container").addClass("content-tags");
              $(".tag-group-content h1 input").prop("disabled", true);
              $(".content-tag-controls").appendTo(".tag-group-content");
            });
          }
        });
        if (currentUser && currentUser.admin) {
          api.modifyClass("component:table-header-toggle", {
            pluginId: "discourse-multilingual",
            click(e) {
              if ($(e.target).parents(".toggle-all").length) {
                return true;
              } else {
                return this._super(e);
              }
            }
          });
        }
      });
    }
  };
});